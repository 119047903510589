/* You can add global styles to this file, and also import other style files */
:root {
    font-size: 16px;
}

::-webkit-scrollbar {
    width: 0.6rem;
    height: 0.6rem;
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #f5f5f5;
    border-radius: 0;
}

::-webkit-scrollbar-thumb {
    background: #f5f5f5;
    box-shadow: inset 0 0 5px #c5c5c5;
    border-radius: 0;
}

::-webkit-scrollbar-thumb:hover {
    box-shadow: inset 0 0 5px #959595;
}

body {
    height: 100vh;
    background: #000;
    color: #7a828b;
}

.noise {
    background-size: auto;
    background-image: linear-gradient( 
        180deg
         , #000000dd 15%, #0c0c0ccc 60%, #2c2c2c 75%, #101010dd 90%), url(/assets/noise.svg);
}

.modal-dialog {
    color: #383838;
    min-width: 60vw;
}

.modal-dialog > .modal-content {
    border-radius: 0;
}

button {
    border-radius: 0 !important;
}

label {
    margin-bottom: 0.25rem;
    font-weight: 600;
}

.switch {
    position: relative;
    display: inline-block;
    width: 58px;
    height: 28px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked + .slider {
    background-color: #2196F3;
}

input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
}

input:disabled + .slider {
    background-color: #9cacb9;
}
  
input:checked + .slider:before {
    -webkit-transform: translateX(28px);
    -ms-transform: translateX(28px);
    transform: translateX(28px);
}

.slider.round {
    border-radius: 34px;
}
  
.slider.round:before {
    border-radius: 50%;
}

.logo {
    width: 5rem;
    height: 5rem;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.error-block {
    margin: 0.5rem 0;
    padding: 0.5rem;
    background: #c60e0e;
    color: #fff;

}

.dropdown-item:hover {
    cursor: pointer;
    background-color: #ccc;
}

/* loading box */

.loading {
    padding: 2rem;
    font-size: 1.5rem;
}

.loading-box {
    box-sizing: border-box;
    border: 1px solid #7a828b;
    width: 32px;
    height: 32px;
    -webkit-animation: spin 4s infinite linear;
    animation: spin 4s infinite linear;
    margin: 1rem auto;
}

.loading-box:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    box-sizing: border-box;
    border: 1px solid #7a828b;
    width: 26px;
    height: 26px;
    -webkit-animation: pulse 2s infinite ease;
    animation: pulse 2s infinite ease;
}

@-webkit-keyframes pulse {
    50% {
        border-width: 10px;
    }
}

@keyframes pulse {
    50% {
        border-width: 10px;
    }
}

@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}